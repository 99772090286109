<template>
  <label :for="id" class="label"
    ><slot></slot><sup class="text-red-600" v-if="required">*</sup></label
  >

  <textarea
    :name="id"
    :id="id"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    class="input"
    :class="{ 'ring ring-red-500 ring-opacity-50': error }"
    :rows="rows ? rows : 5"
    :placeholder="placeholder"
  >
  </textarea>

  <p v-if="limit || error" class="flex flex-wrap justify-between gap-4">
    <span>
      <span class="text-xs text-red-600" role="alert" v-if="error">
        {{ error }}
      </span>
    </span>
    <span>
      <span
        class="text-xs text-right"
        :class="{ 'text-green-600': withinLimit, 'text-red-600': !withinLimit }"
        role="alert"
        v-if="limit"
      >
        {{ characterCount }} characters of {{ limit }}
      </span>
    </span>
  </p>
</template>

<script>
export default {
  props: ["id", "error", "modelValue", "required", "rows", "limit", "placeholder"],
  emits: ["update:modelValue"],
  computed: {
    characterCount() {
      return this.modelValue.length;
    },
    withinLimit() {
      return this.limit && this.characterCount <= this.limit;
    },
  },
};
</script>
